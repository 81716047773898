@tailwind base;
@tailwind components;
@tailwind utilities;

header ul li{
    @apply px-2;
}
.bg-primary{
    background: rgb(19, 36, 33);
}
.bg-secondary{
  background: #305a5f;
}
.mobile-nav{
    @apply fixed top-10 left-0 bg-primary w-full h-1/4;
}
.mobile-nav li{
    @apply w-full h-full text-center border-b-0;
}
html{
    scroll-behavior: smooth;
}